import React, { Fragment } from 'react';

import getServiceColor from 'helpers/getServiceColor';

import Text from 'components/UI/Text/Text';
import Section from 'components/UI/Section/Section';
import Card from 'components/UI/Card/Card';
import Title from 'components/UI/Title/Title';
import Promo from 'components/UI/Promo/Promo';

import ContentfulRichText from 'components/UI/ContentfulRichText/ContentfulRichText';
import ServicesList from 'components/Services/List';

import diagramSvg from './icons/diagram.svg';

const SERVICE_COLOR = getServiceColor('Sales');

const Sales = ({ pageData, servicesData, clientsData, partnersData }) => {
  const {
    title,
    subtitle,
    blockInfo,
    blockFeature,
    description,
    clients,
    partners,
    caseStudies,
  } = pageData;

  return (
    <Section>
      <Title>
          <Text as="h2" line lineColor={SERVICE_COLOR} stack>{title}</Text>
          <Promo data={subtitle.json.content} />
          <ContentfulRichText
        richTextAST={description.json}
        headingsProps={{ as: 'h4' }}
        contentProps={{ variant: 'desc' }}
      />

        </Title>
        <Promo data={blockInfo.json.content} />



      <ServicesList
        title="Tailored Services"
        data={servicesData}
        serviceColor={SERVICE_COLOR}
      />

      <ContentfulRichText
        richTextAST={blockFeature.json}
        headingsProps={{ as: 'h4', line: true, lineColor: SERVICE_COLOR }}
        contentProps={{ variant: 'desc' }}
        contentImageUrl={diagramSvg}
      />

      {clientsData && partnersData && <Text as="h3" line>Community</Text>}
      {partnersData && (
        <Fragment>
          <Text as="h4" line lineColor={SERVICE_COLOR}>Partners</Text>
          <ul style={{ marginBottom: -32 }}>
          {partnersData.map(({node: { logo, name, partnerUrl}}) => (
              <Card
                key={name}
                alt={name}
                small
                href={partnerUrl}
                icon={logo.file.url}
                bgColor="#fff"
              />
            ))}
          </ul>
        </Fragment>
      )}

      {clientsData && (
        <Fragment>
          <Text as="h4" line lineColor={SERVICE_COLOR}>Clients</Text>
          <ul style={{ marginBottom: -32 }}>
          {clientsData.map(({node: { logo, name, clientUrl}}) => (
              <Card
                key={name}
                alt={name}
                small
                href={clientUrl}
                icon={logo.file.url}
                bgColor="#fff"
              />
            ))}
          </ul>
        </Fragment>
      )}
    </Section>
  );
}

export default Sales;
