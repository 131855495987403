import React, { Fragment } from 'react';

import getServiceColor from 'helpers/getServiceColor';

import Card from 'components/UI/Card/Card';
import Text from 'components/UI/Text/Text';
import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';

const List = ({ title, titleAs, data, serviceColor, additionalContent }) => (
  <Fragment>
    <Text as={titleAs || 'h4'} line lineColor={serviceColor}>{title}</Text>
    <ul style={{ marginBottom: -32 }}>
      {data.map(({ node }) => (
        <Card
          key={node.id}
          title={node.title}
          subtitle={node.subtitle}
          color={serviceColor || getServiceColor(node.typeOfService)}
          bgColor="#fff"
        >
          {node.description && (
            <ContentfulRichText
              richTextAST={node.description.json}
              contentProps={{ variant: 'desc', small: true, mb: 10, mt: 10 }}
            />
          )}
        </Card>
      ))}
      {additionalContent}
    </ul>
  </Fragment>
);

export default List;
