import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import hexToRgb from 'helpers/hexToRgb';
import Text from 'components/UI/Text/Text';

const getRootWidth = (count, margin) => `calc(${100 / count}% - ${margin}px)`;
const getInteractSelectors = isOpen => `&:hover,&:active${isOpen ? ',&' : ''}`;

export const StyledRoot = styled.li`
  position: relative;
  display: inline-block;
  margin-right: 0;
  margin-bottom: 16px;
  list-style: none;

  ${({ hasLink }) => hasLink && `cursor: pointer;`}
  ${({ promo }) => promo && `box-shadow: inset 0 0 0 2px #ccc;`}

  ${({ expo, bgColor }) => !expo && bgColor && css`
    background-color: ${bgColor};
  `}

  ${({ color, expo, clear, isOpen }) => {
    if (expo || clear) {
      return;
    }

    if (color) {
      const colorRGB = hexToRgb(color);

      return `
        position: relative;
        transition: all .1s ease-out;

        ${getInteractSelectors(isOpen)} {
          background-color: rgba(${colorRGB}, .04);
          box-shadow: inset 4px 0 0 rgb(${colorRGB});
        }
      `;
    }

    return `
      transition: background-color .1s ease-out;

      &:hover {
        background-color: rgba(0,0,0,.04);
      }
    `;
  }}

  ${({ small, clear }) => {
    const count = small ? 2 : 1;
    const margin = small ? 8 : 32;
    const width = small || count === 1 ? '100%' : getRootWidth(count, margin);

      return `
      width: ${width};
    `;
  }}

  ${({ small, clear }) => small && !clear && css`
    min-height: ${small ? 128 : 160}px;
  `}

  ${MEDIA.TABLET`
    min-height: 218px;
    margin: ${({ small }) => {
      const m = small ? 8 : 32;
      return `0 ${m}px ${m}px 0`;
    }};

    ${({ small, expo }) => {
      const count = small ? 4 : 2;
      const margin = small ? 8 : 32;
      const width = getRootWidth(count, margin);

      return css`
        width: ${width};
        ${expo && css`
          width: 100%;
          margin-right: 0;
        `}
      `;
    }}

    ${({ small, clear }) => small && !clear && css`
      min-height: ${small ? 218 : 320}px;
    `}
  `}

  ${MEDIA.TABLET_XL`
    ${({ color, isOpen }) => {
      if (color) {
        return `
          ${getInteractSelectors(isOpen)} {
            box-shadow: inset 16px 0 0 ${color};
          }
        `;
      }
    }}
  `}

  ${({ isOpen }) => isOpen && css`width: 100% !important;`}
`;

export const StyledIcon = styled.div`
  height: ${({ small }) => small ? `128px` : '160px'};
  ${({ iconCover }) => !iconCover && `margin: 10px`};

  ${({ icon, iconCover }) => `
    background: url(${icon}) no-repeat center;
    background-size: ${iconCover ? 'cover' : 'contain'}};
  `}

  ${({ bgColor }) => bgColor && css`background-color: ${bgColor};`}

  ${MEDIA.TABLET`
    height: ${({ small }) => small ?`218px` : '320px'};

    ${({ expo }) => expo && css`
      height: 540px;
      max-width: 60%;
    `}
  `}
`;

export const StyledInner = styled.a`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;

  height: 100%;
  ${({ clear }) => !clear && css`padding: 24px;`}
  ${({ expo, bgColor }) => expo && bgColor && css`
    background-color: ${bgColor};
  `}
  text-decoration: none;

  ${MEDIA.TABLET`
    ${({ clear }) => !clear && css`padding: 48px 64px;`}
    ${({ expo }) => expo && css`
      position: absolute;
      right: 0;
      bottom: 0;
      width: 544px;
      height: 410px;
    `}
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ promo }) => promo && css`
    ;width: 100%;
    text-align: center;
    font-weight: bold;
  `}
`;

export const StyledDesc = styled(Text)`
  flex-grow: 1;
`;

export const StyledLink = styled.span`
  position: relative;
  margin-top: 32px;

  color: rgba(24, 20, 24, 0.8);
  font-size: 12px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -5px;
    height: 0.1rem;
    background-color: #181418;
  }

  ${MEDIA.TABLET`
    margin-top: 48px;

    ${({ expo }) => expo ? `margin-top: 77px;` : ''}
  `}

  ${({ promo }) => promo && css`
    margin-top: 12px !important;
    align-self: center;

    font-size: 16px;
    font-weight: bold;
    color: #F25018;

    &::before {
      content: none;
    }
  `}
`;
