import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import getServiceColor from 'helpers/getServiceColor';

import Text from 'components/UI/Text/Text';
import MetaBar from 'components/UI/MetaBar/MetaBar';
import {
  StyledRoot,
  StyledInner,
  StyledIcon,
  StyledTitle,
  StyledDesc,
  StyledLink,
} from './styled';

const Card = props => {
  const {
    clear,
    small,
    promo,
    expo,

    icon,
    iconCover,
    title,
    subtitle,
    subtitleSmall,
    date,
    tag,
    desc,
    children,
    href, // linkText,

    bgColor,

    defaultOpen,
  } = props;
  const [isOpen, setOpen] = useState(!!defaultOpen);
  const rootProps = {
    ...props,
    title: typeof title === 'string' && title,
  };

  const needInner = ['tag', 'date', 'title', 'desc', 'linkText']
    .some(p => props[p]);
  const innerProps = { clear, expo, bgColor };
  const iconProps = { small, expo, icon, iconCover, bgColor };
  const titleProps = { promo, expo };

  let linkText = props.linkText;
  if (!linkText && (desc || children)) {
    linkText = `${isOpen ? 'Hide details' : 'More +'}`;
    innerProps.onClick = function() {
      setOpen(!isOpen);
    };
  }

  if (!needInner && href) {
    Object.assign(rootProps, { as: 'a', href, target: '_blank' });
  }

  return (
    <StyledRoot {...rootProps} isOpen={isOpen} hasLink={!!linkText}>
      {icon && <StyledIcon {...iconProps} alt={title} />}
      {needInner && <StyledInner href={href} {...innerProps}>
        <MetaBar tag={tag} date={date} />
        {title && (
          <StyledTitle
            as={clear && 'h5'}
            variant="subtitle"
            bold
            stack={!!subtitle}
            {...titleProps}
          >
            {title}
          </StyledTitle>
        )}
        {subtitle && (
          <StyledTitle {...titleProps} variant="subtitle" small gray>
            {subtitle}
          </StyledTitle>
        )}
        {isOpen && (
          <Fragment>
            {desc && <StyledDesc variant="desc" small>{desc}</StyledDesc>}
            {children}
          </Fragment>
        )}
        {linkText && <StyledLink promo={promo}>{linkText}</StyledLink>}
      </StyledInner>}
    </StyledRoot>
  );
}

Card.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  linkText: PropTypes.string,
  href: PropTypes.string,
  color: PropTypes.string,
};

export default Card;
