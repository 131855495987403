import React from 'react';
import { graphql } from 'gatsby'

import Layout from 'components/Layout/Layout';
import Sales from 'components/Sales/Sales';

const SalesPage = ({ data }) => <Layout page="sales">
  <Sales
    pageData={data.allContentfulPage.edges[0].node}
    servicesData={data.allContentfulService.edges}
    clientsData={data.allContentfulClients.edges}
    partnersData={data.allContentfulPartners.edges}
  />
</Layout>;

export default SalesPage;
export const query = graphql`
  query {
    allContentfulPage(
      filter: {
        slug: {
          eq: "sales"
        }
      }
    ) {
      edges {
        node {
          slug
          title
          subtitle { json }
          blockInfo { json }
          blockFeature { json }
          description { json }
          clients
          partners
          caseStudies
        }
      }
    }

    allContentfulService(
      filter: {
        typeOfService: { eq: "Sales" }
      }
    ) {
      pageInfo { hasNextPage }
      totalCount
      edges {
        node {
          id
          typeOfService
          title
          subtitle
          description { json }
        }
      }
    }
    allContentfulClients(filter:{typeOfService:{eq:"Sales"}}, limit:10)
    {
      edges{
        node{
          name
          logo { file { url } }
          clientUrl
          industry
        }
      }
    }
    allContentfulPartners(filter:{typeOfService:{eq:"Sales"}}, limit:10)
    {
      edges{
        node{
          name
          logo { file { url } }
          partnerUrl
          industry
        }
      }
    }

  }
`;

